const favicons = [
	"images/favicon/r.svg",
	"images/favicon/a.svg",
	"images/favicon/b.svg",
	"images/favicon/b2.svg",
	"images/favicon/l.svg",
	"images/favicon/e.svg",
];

function cycleFavicons(favicons) {
	let index = 0;
	const faviconElement = document.querySelector('link[rel="icon"]');
	let intervalId = null;

	function startCycle() {
		intervalId = setInterval(() => {
			faviconElement.href = favicons[index];
			index = (index + 1) % favicons.length;
		}, 300);
	}

	function stopCycle() {
		clearInterval(intervalId);
		intervalId = null;
		faviconElement.href = "images/favicon/r.svg";
	}

	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState === "visible") {
			stopCycle();
		} else {
			startCycle();
		}
	});
}
cycleFavicons(favicons);
