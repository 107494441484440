import { inView, spring, animate, stagger } from "motion";

// video component
inView("[x-data='video']", ({ target }) => {
	const items = target.querySelectorAll("video, button");
	animate(
		items,
		{ opacity: [0, 1], y: [100, 0] },
		{
			delay: stagger(0.3),
			duration: 0.3,
			easing: spring(),
		}
	);
});

// work section
inView("section[data-title='work']", ({ target }) => {
	const items = target.querySelectorAll("li");
	animate(
		items,
		{ opacity: [0, 1], y: [100, 0] },
		{
			delay: stagger(0.1, { start: 0.6 }),
			duration: 0.3,
			easing: spring(),
		}
	);
});

// contact section
inView("section[data-title='contact']", ({ target }) => {
	const items = target.querySelectorAll("p, li");
	animate(
		items,
		{ opacity: [0, 1], y: [100, 0] },
		{
			delay: stagger(0.1, { start: 0.6 }),
			duration: 0.3,
			easing: spring(),
		}
	);
});

// footer
inView("footer", ({ target }) => {
	const items = target.querySelectorAll("svg, p");
	animate(
		items,
		{ opacity: [0, 1], y: [100, 0] },
		{
			delay: stagger(0.1),
			duration: 0.3,
			easing: spring(),
		}
	);
});

inView("p", (item) => {
	animate(
		item.target,
		{ opacity: [0, 1], y: [100, 0] },
		{ delay: 0.3, duration: 0.3, easing: spring() }
	);
});

inView("h2", (item) => {
	animate(
		item.target,
		{ opacity: [0, 1], y: [100, 0] },
		{ delay: 0.3, duration: 0.3, easing: spring() }
	);
});

inView(".header-logo", (item) => {
	animate(
		item.target,
		{ opacity: [0, 1], y: [100, 0] },
		{ delay: 0.3, duration: 0.3, easing: spring() }
	);
});
