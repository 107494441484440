export default function video() {
	return {
		playing: false,
		seeking: false,
		id: this.$id("video"),
		init() {
			this.$watch("$store.global.videoPlaying", (value) => {
				if (value !== this.id) {
					this.pause();
				}
			});
			this.$refs.video.addEventListener("play", () => {
				this.playing = true;
				this.$store.global.videoPlaying = this.id;
				this.$refs.video.setAttribute("playing", "");
				// console.log(this.id, "playing");
			});
			this.$refs.video.addEventListener("seeking", () => {
				this.seeking = true;
				// console.log(this.id, "seeking");
			});
			this.$refs.video.addEventListener("seeked", () => {
				this.seeking = false;
				// console.log(this.id, "seeked");
			});
			this.$refs.video.addEventListener("pause", () => {
				this.$nextTick(() => {
					if (!this.seeking) {
						this.playing = false;
						this.$refs.video.removeAttribute("playing");
						// console.log(this.id, "paused");
					}
				});
			});
		},
		play() {
			this.$refs.video.play();
		},
		pause() {
			this.$refs.video.pause();
		},
		toggle() {
			if (this.playing) {
				this.$refs.video.pause();
			} else {
				this.$refs.video.play();
			}
		},
	};
}
