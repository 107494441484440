import { timeline, spring, stagger } from "motion";
export default function indexAnimations() {
	return {
		init() {
			setTimeout(() => {
				// TODO: check if window is already scrolled if not scroll to top then add class
				window.scroll({ top: 0, left: 0, behavior: "smooth" });
				document
					.querySelector("body")
					.classList.add("h-r-screen", "overflow-y-hidden");
				this.mastheadLoadIn();
			}, 100);
		},
		mastheadLoadIn() {
			const loadInTl = [
				[
					"header .logo-complex",
					{ opacity: [0, 1], y: ["100%", "0%"], scale: [0.5, 1] },
					{ easing: spring() },
				],
				[
					"header .logo-complex .letter",
					{
						opacity: [0, 1],
						y: ["100%", "0%"],
						scale: [0.75, 1],
					},
					{
						delay: stagger(0.1, { from: "last" }),
						easing: spring(),
						at: "<",
					},
				],
				[
					"header .logo-complex .logo-complete",
					{ opacity: [0, 1] },
					{ duration: 0.3 },
				],
				[
					"header .logo-complex .logo-layers",
					{ opacity: [1, 0] },
					{ duration: 0.3 },
				],
				[
					"header",
					{ backgroundColor: ["#F7EDE3", "#002D0C"] },
					{ duration: 0.3 },
				],
				[
					"#videoContainer",
					{ opacity: [0, 1], scale: [0, 1] },
					{ duration: 0.6, easing: [0.5, -0.1, 0.1, 1.5] },
				],
				[
					"#enterButton",
					{
						opacity: [0, 1],
						y: ["100%", "0%"],
						scale: [0.75, 1],
					},
					{ duration: 0.3 },
				],
				[
					"#video, #videoOverlay",
					{ opacity: [0, 1] },
					{
						duration: 0.6,
						easing: [0.5, -0.1, 0.1, 1.5],
						at: "<",
					},
				],
			];
			timeline(loadInTl).finished.then(() => {
				// switch logos when timeline is finished
				this.$refs.animateInLogo.classList.add("hidden");
				this.$refs.bounceLogo.classList.remove("hidden");
				this.logoBounce();
			});
		},
		logoBounce() {
			const bounceTl = [
				[
					"header .logo-bounce .letter",
					{
						y: ["0%", "10%"],
						scale: [1, 0.75],
					},
					{
						delay: stagger(0.1, { from: "last" }),
						easing: spring(),
						at: "<",
					},
				],
			];
			timeline(bounceTl, { repeat: Infinity });
		},
		bodyLoadIn() {
			const body = document.querySelector("body");
			const footer = document.querySelector("footer");
			body.classList.remove("h-r-screen", "overflow-y-hidden");

			const masthead = document.querySelector("header");
			const main = document.querySelector("main");
			const button = document.querySelector("#enterButton button");
			const outTl = [
				[
					"#innerContent",
					{ opacity: [1, 0], scale: [1, 0] },
					{ duration: 0.3 },
				],
				[
					"#video, #videoOverlay",
					{ opacity: [1, 0] },
					{ duration: 0.3, at: "<" },
				],
				[
					"#aspectContiner",
					{ width: "calc(100vw - 2em)", height: "calc(100vh - 2em)" },
					{ duration: 0.6 },
				],
				["#border", { opacity: [0, 1] }, { duration: 0.3, at: "<" }],
				[masthead, { opacity: [1, 0] }, { duration: 0.3 }],
				[
					main,
					{ display: ["none", "block", "block"], opacity: [0, 0, 1] },
					{ duration: 0.3, at: "<" },
				],
				[
					footer,
					{ display: ["none", "block", "block"], opacity: [0, 0, 1] },
					{ duration: 0.3, at: "<" },
				],
			];
			timeline(outTl).finished.then(() => {
				masthead.classList.add("hidden");
			});
		},
	};
}
